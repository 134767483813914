const messagesEL = {
  el: {
    parameters: {
      yes: 'Nai',
    },
    com: {
      phase: {
        label: 'Ονομασία',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'SKU',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Κατασκευαστής',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Αριθμός εξαρτήματος',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Εγγύηση',
          months: 'μήνες',
        },
      },
      totalAmount: {
        label: 'Σύνολο',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} ΠΕΡΙΣΣΟΤΕΡΕΣ',
    },
    orders: {
      actions: {
        all: 'Όλες οι παραγγελίες',
      },
      myOrdersScoringBlog: {
        title: 'Οι παραγγελίες μου',
      },
      ordersList: 'Παραγγελίες',
      phase: {
        1: 'Ελήφθη',
        10: 'Βρίσκεται υπό επεξεργασία',
        20: 'Αναμονή πληρωμής',
        30: 'Πληρώθηκε',
        98: 'Έχει περάσει από επεξεργαία',
        99: 'Ακυρώθηκε',
        '01-new': 'Ελήφθη',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Αριθμός πακέτων',
            phase: 'Κατάσταση',
            paid: 'Πληρώθηκε',
            total: 'χωρίς ΦΠΑ',
            totalWithTax: 'Σύνολο',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Καλάθι',
        buyButton: 'Αποστολή παραγγελίας',
        emptyBasket: 'Το καλάθι σας είναι άδειο',
        buyThere: 'Έχω ήδη αγοράσει από εδώ',
        login: 'Θέλω να συνδεθώ',
        notFill: 'Τα στοιχεία παράδοσης δεν έχουν συμπληρωθεί',
        fill: 'Συμπληρώστε τα δεδομένα',
        change: 'Αλλαγή δεδομένων',
        popupButton: 'Εμφάνιση στον χάρτη',
        payment: {
          title: 'ΠΛΗΡΩΜΗ',
          free: 'Δωρεάν',
          changePaymentBtn: 'ΑΛΛΑΓΗ ΜΕΘΟΔΟΥ ΠΛΗΡΩΜΗΣ',
        },
        shipping: {
          title: 'ΠΑΡΑΔΟΣΗ',
          free: 'Δωρεάν',
          changeShippingBtn: 'ΑΛΛΑΓΗ ΠΑΡΑΔΟΣΗΣ',
          popup: {
            title: 'Πληροφορίες',
            text: 'Παράδοση στη διεύθυνση απευθείας από {service} υπηρεσία ταχυμεταφορών',
          },
        },
      },
      actions: {
        all: 'Όλα τα καλάθια',
      },
      myBasketsScoringBlog: {
        title: 'Αποθηκευμένα καλάθια',
      },
      cartSummaryParameters: {
        caption: 'Κωδικός προϊόντος',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Περιγραφή',
      parameters: 'Παράμετροι',
      package: 'Περιεχόμενα συσκευασίας',
      fotoAndVideo: 'Φωτογραφίες και βίντεο',
      alternatives: 'Παρόμοια',
      alternativesText: 'Δυστυχώς, προς το παρόν δεν έχουμε εναλλακτική λύση στο προϊόν που επιλέξατε.',
      questions: 'Ερωτήσεις',
      productDescriptionNotAvailable: 'Η περιγραφή του προϊόντος δεν είναι διαθέσιμη',
    },
    newsletter: {
      phone: '+30 698 669 6633',
      subscribe: {
        title: 'μην χάσετε τις ειδικές προσφορές',
        inputText: 'Η διεύθυνση email σας',
        button: 'Σύνδεση',
      },
    },
    globalNavigation: {
      home: 'Αρχική',
      shopping: 'Ψώνια',
      orders: 'Παραγγελίες',
      shoppingLists: 'Λίστες αγορών',
      articles: 'Προϊόντα',
    },
    homePageWhy: {
      0: {
        title: 'Αγοράστε με ασφάλεια',
        content: 'Είμαστε μέλη της ένωσης ηλεκτρονικού εμπορίου και εγγυόμαστε ασφαλείς διαδικτυακές αγορές.',
      },
      1: {
        title: 'Υποστήριξη Πελατών',
        content: "Η ικανοποίηση των πελατών είναι η πρώτη μας προτεραιότητα, γι' αυτό είμαστε πάντα εκεί για εσάς.",
      },
      2: {
        title: 'Προϊόντα σε απόθεμα',
        content: 'Διαθέτουμε το 98% των προϊόντων μας, έτοιμα για αποστολή αμέσως.',
      },
      3: {
        title: 'Δωρεάν Μεταφορά',
        content: 'Αγοράζοντας επιλεγμένα προϊόντα, έχετε ΔΩΡΕΑΝ μεταφορά σε όλη την Ελλάδα ΧΩΡΙΣ κόστος αντικαταβολής.',
      },
      title: 'ΓΙΑΤΙ ΝΑ ΨΩΝΙΣΕΤΕ ΣΕ ΕΜΑΣ?',
    },
    startPage: {
      title: 'ΓΙΑΤΙ ΑΓΟΡΑΣΤΕ ΜΕ ΜΑΣ',
      gotoShopping: 'ΓΙΑΤΙ ΑΓΟΡΑΣΤΕ ΜΕ ΜΑΣ',
    },
    shoppingCart: {
      buyBtn: 'ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ',
      alternatives: 'Εναλλακτική',
      shipping: {
        changePoint: 'Αλλαγή σημείου',
      },
      agreements: {
        termsRequired: 'Πρέπει να συμφωνήσετε με τους όρους και τις προϋποθέσεις.',
      },
      validation: {
        someProductNotAvailable: 'Ορισμένα προϊόντα δεν είναι διαθέσιμα σε επαρκείς ποσότητες.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Κωδικός εμπορευμάτων:',
    },
    productListPage: {
      title: 'Κατάλογος',
      orders: {
        recommended: 'Προτεινόμενα',
        name: 'Name',
        priceAsc: 'Αύξουσα κατά τιμή',
        priceDesc: 'Φθίνουσα κατά τιμή',
      },
    },
    orderStatuses: {
      cancellation: 'Ακύρωση',
      synchronized: 'Απεστάλη',
      waiting: 'Αναμονή για συγχρονισμό',
    },
    orderPreview: {
      items: 'Αντικείμενα',
    },
    cartRegisterForm: {
      title: 'Φόρμα εγγραφής',
    },
    cartPayment: {
      title: 'Πληρωμή',
    },
    cartPreSummaryPage: {
      title: 'Σύνοψη καλαθιού',
      actions: {
        nextStep: 'Συμφωνώ και συνεχίζω',
      },
    },
    cartCompanyForm: {
      description: "Συμπληρώστε εάν αγοράζετε αγαθά ως επιχείρηση'",
    },
    cartDeliveryForm: {
      description: 'Συμπληρώστε εάν η διεύθυνση χρέωσης είναι διαφορετική από τη διεύθυνση παράδοσης.',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Πότε θα παραλάβω τα προϊόντα?',
    },
    productDetailStockInfo: {
      available: 'Έχουμε τα προϊόντα στην αποθήκη διανομής μας',
      external: 'Το προϊόν βρίσκεται στο δρόμο προς την αποθήκη μας',
      notAvailable: 'Το αντικείμενο δεν είναι διαθέσιμο προς το παρόν',
      labels: {
        free: 'ΔΩΡΕΑΝ ΠΑΡΑΔΟΣΗ',
        showroom: 'ΕΚΤΙΘΕΤΑΙ ΣΤΟΝ ΧΩΡΟ ΠΑΡΟΥΣΙΑΣΗΣ',
      },
    },
    productGifts: {
      btnName: 'ΔΩΡΟ',
      giftValue: 'Σε αξία',
    },
    homepage: {
      productOfMonth: 'Προϊόντα του Μήνα',
      catalogFavorite: 'Αγαπημένες Κατηγορίες',
      hotTips: 'Αποκλειστική Προσφορά',
      recommended: 'Προτεινόμενα',
    },
    productQuestion: {
      firstName: 'Όνομα',
      lastName: 'Επώνυμο',
      email: 'Email',
      subject: 'Θέμα',
      question: 'Ερώτημα',
      gdpr: 'Υποβάλλοντας ένα ερώτημα, αναγνωρίζω την επεξεργασία προσωπικών δεδομένων προκειμένου να απαντήσουν στο ερώτημα.',
      gdprLink: 'Σύνδεσμος GDPR',
      button: 'Υποβολή ερωτήματος',
      subjectContent: 'Ερώτηση για προϊόν: {product}, κωδικός: {sku}',
      title: 'Πρέπει να συναινέσετε στην επεξεργασία προσωπικών δεδομένων.',
      success: 'Εστάλη επιτυχώς',
    },
    lastVisitedProducts: 'Προϊόντα που επισκεφθήκατε πρόσφατα',
    productAlternativeProducts: 'Εναλλακτικά',
    productAccessoryProducts: 'Αξεσουάρ',
    productReplacementProducts: 'Ανταλλακτικά',
    productAccessories: 'Πρόσθετο',
    productReplacements: 'Ανταλλακτικό',
    appProductDetailTabs: {
      description: 'Περιγραφή',
      parameters: 'Παράμετροι',
      package: 'Περιεχόμενα Συσκευασίας',
      query: 'Ερώτημα',
      media: 'Φωτογραφία και βίντεο',
      alternatives: 'Εναλλακτικά',
      accessories: 'Αξεσουάρ',
      replacements: 'Ανταλλακτικά',
      form: {
        enum: '!!!! ΑΞΙΑ ENUM',
      },
    },
    cartSummaryPage: {
      title: 'Σύνοψη πριν από την παραγγελία',
      actions: {
        continueShopping: 'Συνέχεια αγορών',
        saveOrder: 'Δημιουργία παραγγελίας',
      },
      expectedDeliveryDate: {
        label: 'Απαιτούμενη ημερομηνία παράδοσης',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Σημείωση για τον πωλητή',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Πύλη Πληρωμής',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Προφίλ χρήστη',
      content: 'Βαθμολογία',
      contactInfo: {
        newsletter: {
          title: 'Αποστολή ειδοποιήσεων μέσω email',
          content: 'Θα ήθελα να λαμβάνω ειδοποιήσεις από τον ιστότοπο Digiexpert.xx',
        },
        title: 'Στοιχεία Επικοινωνίας',
      },
      orders: {
        title: 'Ιστορικό Παραγγελιών',
      },
      invoices: {
        title: 'Τα τιμολόγιά μου',
      },
      myOrders: 'Οι παραγγελίες μου',
      myInvoices: 'Τα τιμολόγιά μου',
      logout: 'Αποσύνδεση',
      login: 'Σύνδεση',
      favorites: {
        title: 'Αγαπημένα',
      },
      changePassword: {
        title: 'Αλλαγή κωδικού πρόσβασης',
        fields: {
          login: 'Σύνδεση (δεν μπορεί να αλλάξει)',
          originalPassword: 'Αρχικός κωδικός πρόσβασης',
          newPassword: 'Νέος κωδικός πρόσβασης',
          confirmNewPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
        },
        button: 'Αποθήκευση αλλαγών',
      },
    },
    orderSavedThankPage: {
      title: 'Σας ευχαριστούμε για την παραγγελία σας {orderNumber}.',
      thankMessage: {
        template:
          'Ελέγξτε το e-mail σας για να δείτε την παραγγελία σας. Κρατήστε το e-mail για δική σας χρήση. Η κατάσταση της παραγγελία σας είναι:',
        phoneNumber: '',
        email: 'info@digiexpert.gr',
      },
      order: 'Αριθμός παραγγελίας:',
      payment: 'Πληρωμή στις:',
      cancelled: 'Ακυρωμένη',
      accepted: 'Έγινε αποδεκτή',
      pending: 'Σε εκκρεμότητα',
      paid: 'Πληρωμένη',
      actions: {
        back: 'Επιστροφή στην αρχική σελίδα',
        detail: 'ΛΕΠΤΟΜΕΡΕΙΕΣ ΠΑΡΑΓΓΕΛΙΑΣ',
        continue: 'ΣΥΝΕΧΕΙΑ ΑΓΟΡΩΝ',
      },
      decline: 'Απορρίφθηκε',
    },
    shoppingLists: {
      title: 'Λίστες αγορών',
      actions: {
        saveAsShoppingList: 'Αποθήκευση ως λίστα αγορών',
        importShoppingListToCart: 'Εισαγωγή',
      },
    },
    searchResultPage: {
      search: 'Όρος αναζήτησης:',
      results: 'Αποτελέσματα αναζήτησης',
    },
    homePageArticles: {
      title: 'Νέα',
      button: 'Εμφάνιση όλων των άρθρων',
    },
    loginPage: {
      title: 'Σύνδεση',
      forgotPassword: 'Επαναφορά κωδικού πρόσβασης',
      email: 'Email',
      sendEmail: 'Αποστολή Email',
      info: 'Θα λάβετε ένα e-mail με έναν σύνδεσμο για να αλλάξετε τον κωδικό πρόσβασής σας',
      password: 'Κωδικός πρόσβασης',
      notSame: 'όχι ίδιο',
      newPassword: 'Νέος κωδικός πρόσβασης',
      confirmPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
      changePassword: 'αλλαγή κωδικού πρόσβασης',
    },
    productPrice: {
      withoutTax: 'χωρίς ΦΠΑ',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Η παραγγελία σας θα αποσταλεί απο την αποθήκη μας σε {minDays}-{maxDays} μέρες.',
        today: 'Όλες οι παραγγελίες που λαμβάνονται πριν από το {time} θα φορτωθούν την ίδια ημέρα.',
        todayWord: 'σήμερα',
        tomorrow: 'Παραγγείλετε σήμερα {today} και η παραγγελία σας θα σταλεί αύριο το πρωί.',
        nextDateTime: '13:30',
        dayOfWeek: {
          1: 'Δευτέρα',
          2: 'Τρίτη',
          3: 'Τετάρτη',
          4: 'Πέμπτη',
          5: 'Παρασκευή',
          6: 'Σάββατο',
          0: 'Κυριακή',
          message: 'Παραγγείλετε σήμερα {today}, η παραγγελία σας θα σας αποσταλεί {day}.',
        },
        workingDay: 'Παραγγείλετε μέχρι XX: XXh και αποστέλλουμε',
        afterWorkingDay: 'Παραγγείλετε σήμερα και αποστέλλουμε',
      },
      allDescription: 'ΠΛΗΡΗΣ ΠΕΡΙΓΡΑΦΗ ΤΩΝ ΠΡΟΪΟΝΤΩΝ',
      demoProduct: {
        text: 'ΑΓΟΡΑΣΤΕ ΦΘΗΝΟΤΕΡΑ για:',
      },
      productNote: {
        title: 'ΑΓΟΡΑΣΤΕ ΦΘΗΝΟΤΕΡΑ για: X.XXX, 00 €',
        note: 'Σημείωση 2 - Περαιτέρω περιγραφή της κατάστασης ή παρόμοιες πληροφορίες σχετικά με το προϊόν, γιατί έχει έκπτωση και τι συμβαίνει με τα προϊόντα',
      },
      adornments: {
        notForSale: 'Δεν πωλείται ξεχωριστά',
        usedProduct: 'Bazar',
        freeGift: 'Δωρεάν δώρο',
        freeShipping: 'Δωρεάν αποστολή',
        eol: 'Η πώληση τελείιωσε',
        demo: 'Προϊόν παρουσίασης',
        sale: 'Έκπτωση',
      },
      disponibility: {
        primaryLessThan: 'ΣΕ ΑΠΟΘΕΜΑ {count} κομμάτια',
        primaryMoreThan: 'ΣΕ ΑΠΟΘΕΜΑ {count, plural, zero {ένα κομμάτι} other {{count} και περισσότερα κομμάτια}}',
        secondaryLessThan: 'ΣΕ ΑΠΟΘΕΜΑ {count} κομμάτια στην αποθήκη προετοιμασίας',
        secondaryMoreThan: 'ΣΕ ΑΠΟΘΕΜΑ {count, plural, zero {ένα κομμάτι} other {{count} και περισσότερα κομμάτια}}',
        external: 'Το προϊόν βρίσκεται στο δρόμο προς την αποθήκη μας',
        unavailable: 'Μη διαθέσιμο',
        showroom: '(εκτίθεται στο Χώρο Παρουσίασης)',
        soldout: 'Εξαντλήθηκε',
        'on-way': {
          d: '{from, plural, one {Θα παραδοθεί αύριο} two {Θα παραδοθεί μεθαύριο} few {Θα παραδοθεί από {from} ημέρες} other {Θα παραδοθεί σε {from} ημέρες}}',
          m: 'Στο δρόμο σε {from, plural, one {λεπτό} two {λεπτά} other {λεπτά}}',
          M: 'Στο δρόμο σε {from, plural, one {μήνα} two {μήνες} ​​few {μήνες} ​​other {μήνες}}',
          dRange: 'Στο δρόμο σε {to, plural, few {{from} - {to} ημέρες} other {{from} - {to} ημέρες}}',
          mRange: 'Στο δρόμο σε {from} - {to, plural, one {ημέρα} few {ημέρες} other {ημέρες}}',
          MRange: 'Στο δρόμο σε {from} - {to, plural, one {μήνα} two {μήνες} ​​few {μήνες} ​​other {μήνες}}',
          date: '{date}',
        },
        time: 'Στο δρόμο για {count, plural, zero {ένα κομμάτι} other {{count} και περισσότερα κομμάτια}}',
      },
    },
    comparePage: {
      title: 'Σύγκριση',
      removeButton: 'Κατάργηση προϊόντος',
      nothing: 'Τίποτα για σύγκριση',
    },
    filter: {
      reset: 'Επαναφορά φίλτρου',
      search: 'Αναζήτηση',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Όροι παραγγελίας',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Προσθήκη στο καλάθι',
      },
    },
    invoiceBlogPreview: {
      title: 'Τα τιμολόγιά μου',
      button: 'Όλα τα τιμολόγια',
    },
    userProfilePage: {
      logout: 'Αποσύνδεση',
      setting: 'Ρύθμιση',
      passwordChange: 'Αλλαγή κωδικού πρόσβασης',
    },
    appBarActionRegion: {
      changeRegion: 'Αλλαγή γλώσσας',
      label: 'Έξοδος',
    },
    subCategories: {
      title: 'Υποκατηγορία',
    },
    aboutShoppingPage: {
      title: 'ΤΑ ΠΑΝΤΑ ΓΙΑ ΤΗΝ ΑΓΟΡΑ',
      contact: 'ΟΛΑ ΤΑ ΑΓΟΡΑ',
      electronicClosureOfRevenue: 'ΗΛΕΚΤΡΟΝΙΚΟ ΕΓΓΡΑΦΟ ΕΙΣΟΔΩΝ',
      informationDuty: 'ΥΠΟΧΡΕΩΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ',
      processingOfPersonalData: 'ΕΠΕΞΕΡΓΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ',
      cashback: 'CASHBACK',
      delivery: 'ΠΑΡΑΔΟΣΗ',
      availability: 'ΔΙΑΘΕΣΙΜΟΤΗΤΑ',
      methodOfPayment: 'ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ',
      termsAndConditions: 'ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ',
      termsOfAppeal: 'ΟΡΟΙ ΕΦΑΡΜΟΓΗΣ',
      contractwWithdrawal: 'ΑΝΑΚΛΗΣΗ ΑΠΟ ΤΗ ΣΥΜΒΑΣΗ',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Ο αριθμός φόρου έχει επαληθευτεί, οι τιμές χρεώνονται χωρίς ΦΠΑ.',
        viesNotVerified:
          'Ο αριθμός φόρου σας δεν έχει επαληθευτεί ή δεν πληροίτε τις προϋποθέσεις για αγορά χωρίς ΦΠΑ.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'Λεπτομέρειες παράδοσης',
      pleaseComplete: 'ΠΑΡΑΚΑΛΟΥΜΕ ΣΥΜΠΛΗΡΩΣΤΕ',
      closeButton: 'Κλείσιμο',
    },
    cartPromoCodes: {
      title: 'ΕΦΑΡΜΟΣΤΕ ΤΟΝ ΠΡΟΩΘΗΤΙΚΟ ΚΩΔΙΚΟ',
      textFieldPlaceholder: 'ΠΡΟΩΘΗΤΙΚΟΣ ΚΩΔΙΚΑΣ',
      textFieldHelperText: 'Είναι δυνατή η εφαρμογή μόνο ενός προωθητικού κωδικά ανά παραγγελία',
      applyPromoCodeButton: 'ΕΦΑΡΜΟΣΤΕ',
    },
    cookiesBar: {
      agreeBtn: 'Συμφωνώ',
      title: '',
      text: 'Ο ιστότοπος digiexpert.gr χρησιμοποιεί cookies για τη βελτίωση των υπηρεσιών μας. Χρησιμοποιώντας αυτόν τον ιστότοπο, αποδέχεστε τους όρους απορρήτου και επιβεβαιώνετε την αποδοχή των cookies.',
    },
    cartFormLogin: {
      btnSend: 'Αποστολή',
      button: 'Σύνδεση',
      title: 'Σύνδεση',
      forgottenPasswordBtn: 'Ξεχάσατε τον κωδικό πρόσβασης?',
      text1: 'Θα στείλουμε έναν κωδικό πρόσβασης μίας χρήσης στην παρεχόμενη διεύθυνση email.',
      text2: 'Μετά τη σύνδεση, προσθέστε έναν νέο κωδικό πρόσβασης.',
      error: 'Εσφαλμένο όνομα χρήστη ή κωδικός πρόσβασης.',
    },
    cartSummartSection: {
      title: 'ΠΕΡΙΛΗΨΗ ΤΗΣ ΣΥΜΒΑΣΗΣ',
    },
    appCartSummarySubjectsList: {
      title: 'Τίτλος',
      text: 'Όνομα',
    },
    newsletterText: {
      text1: 'Χρειάζεστε βοήθεια?',
      text2: 'Μπορείτε να μας καλείτε κάθε εργάσιμη ημέρα από 09:00 έως 17:30',
    },
    cartSummarySection: {
      labelWithoutVAT: '(χωρίς ΦΠΑ)',
      priceLabel: 'ΤΙΜΗ / ΤΕΛΗ',
      amount: 'Ποσό',
      totalPriceLabel: 'ΣΥΝΟΛΙΚΗ ΤΙΜΗ',
      totalPrice: 'Σύνολο',
      submitButton: 'Υποβολή παραγγελίας',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: 'τμχ',
    },
    cartSummaryListItem: {
      freeTitle: '- δωρεάν',
      amountTitle: 'τεμάχιο',
    },
    sxCartNotes: {
      note: 'Δεν αναφέρεται η ημερομηνία παράδοσης',
      addNote: 'Προσθήκη σημείωσης',
    },
    productDetail: {
      promoCode: {
        title: 'με προωθητικό κωδικό:',
        validTo: 'ισχύει έως:',
      },
      productSendLink: {
        copyLinkBtn: 'αντιγραφή συνδέσμου',
        sendLinkByEmail: 'Αποστολή συνδέσμου μέσω email',
      },
      productDetailBarInformation: {
        addToFavourites: 'Προσθήκη στα αγαπημένα',
        compareProduct: 'Σύγκριση προϊόντων',
        sendLink: 'Αποστολή συνδέσμου',
        serviceList: 'Λίστα υπηρεσιών',
        questions: 'Ερωτήσεις',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'Αγορά ως επιχείρηση',
        otherDeliveryInfo: 'Η διεύθυνση παράδοσης είναι διαφορετική',
        otherDeliveryInfoAlert: 'Ένας πληρωτής ΦΠΑ δεν μπορεί να χρησιμοποιήσει άλλη διεύθυνση παράδοσης.',
      },
      companyNameLabel: 'Εταιρεία',
      firstNameLabel: 'Όνομα',
      lastNameLabel: 'Επώνυμο',
      streetLabel: 'Οδός',
      cityLabel: 'Πόλη',
      zipLabel: 'Ταχυδρομικός κώδικας',
      companyIDLabel: 'Κωδικός φόρου',
      countryLabel: 'Χώρα',
    },
    cartFormPersonal: {
      label: 'Διεύθυνση παράδοσης',
      firstNameLabel: 'Όνομα',
      lastNameLabel: 'Επώνυμο',
      streetLabel: 'Οδός',
      pscLabel: 'Ταχυδρομικός κώδικας',
      cityLabel: 'Πόλη',
      countryLabel: 'Χώρα',
      phoneLabel: 'Αριθμός τηλεφώνου',
      emailLabel: 'διεύθυνση email',
      passwordLabel: 'Κωδικός πρόσβασης',
      controlPasswordLabel: 'Έλεγχος κωδικού πρόσβασης',
    },
    cartFormInvoice: {
      labelDesktop: 'Στοιχεία επικοινωνίας / διεύθυνση χρέωσης',
      labelMobile: 'Στοιχεία επικοινωνίας',
      companyLabel: 'Εταιρεία',
      password: 'Κωδικός πρόσβασης',
      confirmPassword: 'Επαλήθευση κωδικού πρόσβασης',
      email: 'Email',
      firstName: 'Όνομα',
      lastName: 'Επώνυμο',
      countryLabel: 'Χώρα',
      phone: 'Αριθμός τηλεφώνου',
      phoneError: 'Ο αριθμός τηλεφώνου είναι λανθασμένος',
      streetLabel: 'Οδός',
      streetNumberLabel: 'Αριθμός',
      zipLabel: 'Ταχυδρομικός κώδικας',
      icoLabel: 'IR',
      dicLabel: 'Αριθμός ΦΠΑ',
      country: 'Χώρα',
      cityLabel: 'Πόλη',
      registration: 'Θέλω να δημιουργήσω έναν λογαριασμό',
      registrationNote: 'και μπορείτε να παρακολουθείτε τις παραγγελίες, τις αποστολές και τις πληρωμές σας ...',
      button: 'Αποθήκευση αλλαγών',
      label: 'Διεύθυνση χρέωσης',
    },
    page404: {
      title: 'Λυπούμαστε, η σελίδα δεν υπάρχει.',
      subTitle: 'Μετάβαση στην αρχική σελίδα.',
      button: 'Επιστροφή στην αρχική σελίδα.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Ταξινόμηση κατά:',
      byName: 'Όνομα',
      byPrice: 'Τιμή',
      onlyStock: 'Μόνο σε απόθεμα',
      resetFilter: 'ακύρωση',
      displayCountOfProducts: 'Προϊόντα:',
      search: 'Αναζήτηση',
      clear: 'Εκκαθάριση',
      ok: 'OK',
    },
    priceRangeSliderField: {
      title: 'Εύρος τιμών',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Φίλτρο κατά',
    },
    productListMultipleFilterMobile: {
      title: 'Φίλτρο',
    },
    cartCompany: {
      companyName: 'Όνομα Εταιρείας',
      regNo: 'Αναγνωριστικό εταιρείας',
      taxNo: 'Αριθμός ΦΟΡΟΥ',
      taxNoPlaceholder: 'EL000000000',
      warningMessage: {
        warning: 'Σημαντικό.',
        message:
          'Εάν παραδοθεί σε διεύθυνση διαφορετική από τη διεύθυνση της εταιρείας, ο ΦΠΑ θα χρεωθεί στην παραγγελία.',
      },
    },
    searchResultsPage: {
      match: 'Βρήκαμε {resultCountFormatted} αποτελέσματα για {searchText}.',
      nomatch: 'Δεν βρήκαμε αποτελέσματα για {searchText}.',
      matchingCategories: 'Βρέθηκαν κατηγορίες',
      button: 'Επιστροφή στην αρχική σελίδα.',
    },
    selectMultipleFilter: {
      totalChipsLabel: '{total} Επιλεγμένα προϊόντα',
    },
    cartDelivery: {
      firstName: 'Όνομα',
      lastName: 'Επώνυμο',
      companyName: 'Όνομα εταιρείας',
      streetLabel: 'Οδός',
      streetNumberLabel: 'Περιγραφικός αριθμός',
      zipLabel: 'Ταχυδρομικός κώδικας',
      country: 'Χώρα',
      cityLabel: 'Πόλη',
      street: 'Οδός, περιγραφικός αριθμός',
      zip: 'Ταχυδρομικός κώδικας',
      city: 'Πόλη',
    },
    cartNotes: {
      shippingLabel: 'Προσθήκη σημείωσης για τον μεταφορέα',
      shippingNote: 'Σημείωση για τον μεταφορέα',
      sellerLabel: 'Προσθήκη σημείωσης για τον πωλητή',
      sellerNote: 'Σημείωση για τον πωλητή',
      loginNote: 'Εάν έχετε ήδη λογαριασμό σε εμάς, θα εκπληρώσουμε τα πάντα για εσάς μετά την εγγραφή.',
    },
    cartCountryShipping: {
      title: 'Χώρα παράδοσης',
      label: 'Η επιλογή χώρας επηρεάζει τον τρόπο πληρωμής, την αποστολή και την ημερομηνία παράδοσης',
    },
    cartAddress: {
      bllingAddress: 'Διεύθυνση χρέωσης',
      deliveryAddress: 'Διεύθυνση παράδοσης',
      pickUpPointAddress: 'Διεύθυνση σημείου παραλαβής',
    },
    cartAgreements: {
      customerVerified:
        'Δεν συμφωνώ με την αποστολή του ερωτηματολογίου ικανοποίησης ως μέρος του προγράμματος Επαλήθευσης πελατών, το οποίο συμβάλλει στη βελτίωση των υπηρεσιών.',
    },
    loginDialog: {
      username: 'Όνομα χρήστη',
      password: 'Κωδικός πρόσβασης',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Οι κωδικοί πρόσβασης που έχουν εισαχθεί δεν είναι οι ίδιοι',
      invalidPostalCode: 'Ο καθορισμένος ταχυδρομικός κώδικας δεν είναι σε έγκυρη μορφή',
      usernameIsNotFree: 'Ένας χρήστης με αυτό το όνομα έχει ήδη εγγραφεί.',
      emailEmpty: 'Μη έγκυρο email!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Προσθήκη νέου τιμολογίου',
        all: 'Όλα τα τιμολόγια',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Αριθμός',
            invoiceDate: 'Ημερομηνία τιμολογίου',
            dueDate: 'Προθεσμία',
            total: 'χωρίς ΦΠΑ',
            totalWithTax: 'Σύνολο',
            billingAddress: 'Διεύθυνση χρέωσης',
            orderNumber: 'Αριθμός παραγγελίας',
            unpaidAmount: 'Ποσό που δεν πληρώθηκε',
          },
          filter: {
            number: 'Αριθμός',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Σύνοψη Τιμολογίου',
          columns: {
            priceUnit: 'Τιμή / Μονάδα',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Συνολική Τιμή Παραγγελίας',
      tax: 'ΦΠΑ',
      withoutTax: 'Συνολική τιμή χωρίς ΦΠΑ',
    },
    compare: {
      addToCompare: 'Το προϊόν προστέθηκε στη σύγκριση',
      removeToCompare: 'Το προϊόν έχει αφαιρεθεί από το πλαίσιο σύγκρισης',
      price: 'Price',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Το προϊόν προστέθηκε στα αγαπημένα',
      removeFromFavorite: 'Το προϊόν αφαιρέθηκε από τα αγαπημένα',
    },
    registration: {
      important: 'ΣΗΜΑΝΤΙΚΟ!',
      note: 'Θα σας στείλουμε έναν κωδικό πρόσβασης μίας χρήση μέσω email.',
      gdprAgreement: 'Πρέπει να συναινέσετε στην επεξεργασία προσωπικών δεδομένων',
      submitButton: 'Εγγραφή',
      fail: 'Η εγγραφή απέτυχε. Δοκιμάστε ξανά αργότερα',
      success: 'Η εγγραφή ήταν επιτυχής',
      title: 'Εγγραφή',
      dialog: 'Εάν εξακολουθείτε να μην έχετε λογαριασμό μαζί μας, μπορείτε να εγγραφείτε',
      here: 'εδώ',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Αναζήτηση προϊόντων',
    },
    appBarActions: {
      about: 'Τα πάντα για την αγορά',
      aboutHref: '/για-την-αγορά',
      compare: 'Σύγκριση',
    },
    appBarActionUser: {
      title: 'Είσοδος',
    },
    footer: {
      copyright: 'Πνευματικά δικαιώματα © ČESKÝ VELKOOBCHOD S.R.O. Όλα τα δικαιώματα προστατεύονται',
    },
    appArticlesPage: {
      title: 'Κείμενα',
    },
    errorPage: {
      title: 'Κάτι δεν πάει καλά.',
      button: 'Αρχική σελίδα',
    },
    cartSummary: {
      withoutTax: 'χωρίς ΦΠΑ',
    },
    renewPasswordForm: {
      success: 'Ένας νέος κωδικός πρόσβασης έχει σταλεί στη διεύθυνση email σας',
      emailNotFound: 'Το email δεν βρέθηκε!',
      error: 'Κάτι δεν πάει καλά.',
    },
    cartSubjectListItemQuantityWarning: 'Ο τρέχων αριθμός προϊόντων σε απόθεμα είναι {count}',
  },
};

export default messagesEL;
