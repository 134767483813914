import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
// @ts-ignore
import AppLoadShoppingInfo from '@sprinx/cvo-eshop-ui-common/app/AppLoadShoppingInfo';
// @ts-ignore
// import MobileMainNavigation from '@sprinx/cvo-eshop-ui-common/layout/MobileMainNavigation';
import AppSettingsProvider from '@sprinx/cvo-eshop-ui-common/app/AppSettingsProvider';
// @ts-ignore
import SnackbarProvider from '@sprinx/cvo-eshop-ui-common/app/SnackbarProvider';
// @ts-ignore
import LoginProvider from '@sprinx/cvo-eshop-ui-common/auth/LoginProvider';
// @ts-ignore
import useGtmPageViews from '@sprinx/cvo-eshop-ui-common/hooks/useGtmPageViews';
// import AppAuthHandlerImp from '@sprinx/cvo-eshop-ui-common/app/AppAuthHandler';
// @ts-ignore
// import AppConnectServersImp from '@sprinx/cvo-eshop-ui-common/app/AppConnectServers';
// @ts-ignore
// import CommerceBoilerplate from '@sprinx/cvo-eshop-ui-common/app/CommerceBoilerplate';
// @ts-ignore
import AppBarActions from '@sprinx/cvo-eshop-ui-common/layout/components/AppBarActions';
// @ts-ignore
import ProductDetailShippingDateDefault from '@sprinx/cvo-eshop-ui-common/pages/ProductDetailPage/components/ProductDetailShippingDateDefault';
// @ts-ignore
import ShoppingProvider from '@sprinx/cvo-eshop-ui-common/shop/ShoppingProvider';
// @ts-ignore
import ViesHandlerImp from '@sprinx/cvo-eshop-ui-common/shop/ViesHandler';
import withNullIfStaticBuild from '@sprinx/cvo-eshop-ui-common/withNullIfStaticBuild';
// @ts-ignore
import AuthProvider from '@sprinx/react-auth/AuthProvider';
// @ts-ignore
import MuiComponentsProvider from '@sprinx/react-mui-components/MuiComponentsProvider';
import MuiFieldsProvider from '@sprinx/react-mui-fields/MuiFieldsProvider';
// @ts-ignore
import LayoutProvider from '@sprinx/react-mui-layout/LayoutProvider';
import useRedirect from '@sprinx/react-routes/useRedirect';
import useRouteLocation from '@sprinx/react-routes/useRouteLocation';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link as ReactRouterLink } from 'react-router-dom';
import AppAuthHandlerImp from './AppAuthHandler';
import AppComponentLoader from './AppComponentLoader';
import AppConnectServers from './AppConnectServers';
import AppGlobalize from './AppGlobalize';
// @ts-ignore
import applicationSettings2 from './applicationSettings2';
import CommerceBoilerplate from './CommerceBoilerplate';
import AgreementFieldCustomerVerified from './components/AgreementFieldCustomerVerified';
import AgreementFieldGdpr from './components/AgreementFieldGdpr';
import AgreementFieldProductGdpr from './components/AgreementFieldProductGdpr';
import AgreementFieldTerms from './components/AgreementFieldTerms';
import Footer from './components/Footer';
import MainLogo from './components/MainLogo';
import useAddGtmConfigBody from './gtm/useAddGtmConfigBody';
// @ts-ignore
import ProductDetailCertificate from './pages/ProductDetailPage/components/ProductDetailCertificate';
// @ts-ignore
// import ProductDetailShippingDate from './pages/ProductDetailPage/components/ProductDetailShippingDate';
import { routeUrl } from './routesBuilder';
import theme from './theme';

// const SnackbarProvider = withChildrenIfStaticBuild(SnackbarProviderImp);
// const AppConnectServers = withChildrenIfStaticBuild(AppConnectServersImp);
const AppAuthHandler = withNullIfStaticBuild(AppAuthHandlerImp);
const ViesHandler = withNullIfStaticBuild(ViesHandlerImp);

const MobileMainNavigation = () => null;

const region = process.env.RAZZLE_SX_REGION || 'GR';

const defaultCurrency = process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK';
const defaultLocale = process.env.RAZZLE_SX_LOCALE || 'en';
const supportedLocales = [defaultLocale];
const anonymousContact = { id: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'cvoEshop-GR-anonymous' };
const siteRoot = process.env.RAZZLE_SX_SITE_ROOT || '';

const applicationTitle = 'Digiexpert.gr';
// const titleTemplate = '%s';

export interface AppProps {
  children: NonNullable<React.ReactNode>;
  initialAppProps: Partial<Record<string, any>>;
}

const App: React.FC<AppProps> = ({ children /*, initialAppProps*/ }) => {
  useGtmPageViews();
  return (
    <>
      <Helmet titleTemplate='%s | Digiexpert.gr' defaultTitle='Digiexpert.gr' />
      <React.Suspense fallback={<AppComponentLoader />}>
        <AppInitialized>{children}</AppInitialized>
      </React.Suspense>
    </>
  );
};

App.displayName = 'App';

export default App;

const getSkroutzAnalyticsHead = () => {
  const id = 'SA-14097-6715';
  return `(function(a,b,c,d,e,f,g){a['SkroutzAnalyticsObject']=e;a[e]= a[e] || function(){
  (a[e].q = a[e].q || []).push(arguments);};f=b.createElement(c);f.async=true;
  f.src=d;g=b.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g);
})(window,document,'script','https://analytics.skroutz.gr/analytics.min.js','skroutz_analytics');
skroutz_analytics('session', 'connect', '${id}');  // Connect your Account.
`;
};

const AppInitialized: React.FC = ({ children }) => {
  useAddGtmConfigBody();
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script>{getSkroutzAnalyticsHead()}</script>
      </Helmet>
      <CssBaseline />
      {/* <SseListenerConnect /> */}
      <SnackbarProvider>
        <AuthProvider
          isAuthenticated={false}
          useLocation={useRouteLocation}
          //  @ts-ignore
          useRedirect={useRedirect}
          meta={{}}
          render={({ accessToken, /* authenticate, */ registerOnAuthenticate, registerOnLogout }) => (
            <AppConnectServers
              // @ts-ignore
              accessToken={accessToken as string}
            >
              <AppAuthHandler
                // @ts-ignore
                registerOnAuthenticate={registerOnAuthenticate}
                registerOnLogout={registerOnLogout}
              />
              <AppGlobalize>
                <ShoppingProvider
                  region={region}
                  defaultCurrency={defaultCurrency}
                  customer={anonymousContact}
                  stockOrderingGroup={process.env.RAZZLE_SX_STOCK_ORDERING_GROUP}
                >
                  <AppSettingsProvider
                    AgreementFieldCustomerVerifiedComponent={AgreementFieldCustomerVerified}
                    AgreementFieldGdprComponent={AgreementFieldGdpr}
                    AgreementFieldProductGdprComponent={AgreementFieldProductGdpr}
                    AgreementFieldTermsComponent={AgreementFieldTerms}
                    phoneNumbersIncludeCountries={
                      applicationSettings2.shop.contacts.phoneNumbers.includeCountries as [string, number][]
                    }
                    billingIncludeCountries={applicationSettings2.shop.billing.includeCountries}
                    shippingIncludeCountries={applicationSettings2.shop.shipping.includeCountries}
                    defaultCurrency={defaultCurrency}
                    defaultLocale={defaultLocale}
                    routeUrl={routeUrl as any}
                    siteRoot={siteRoot}
                    ProductDetailCertificateComponent={ProductDetailCertificate}
                    ProductDetailShippingDateComponent={ProductDetailShippingDateDefault}
                  >
                    <AppLoadShoppingInfo
                      anonymousContact={anonymousContact}
                      registerOnAuthenticate={registerOnAuthenticate}
                      registerOnLogout={registerOnLogout}
                    />
                    <MuiComponentsProvider linkComponent={ReactRouterLink}>
                      <MuiFieldsProvider currencyDefault={defaultCurrency} supportedLanguages={supportedLocales}>
                        <LayoutProvider
                          appBarActions={AppBarActions}
                          appMenu={MobileMainNavigation}
                          logo={MainLogo}
                          footer={Footer}
                          title={applicationTitle}
                        >
                          <CommerceBoilerplate>
                            <ViesHandler viesInvoiceCountry='CZ' />
                            <LoginProvider>{children}</LoginProvider>
                          </CommerceBoilerplate>
                        </LayoutProvider>
                      </MuiFieldsProvider>
                    </MuiComponentsProvider>
                  </AppSettingsProvider>
                </ShoppingProvider>
              </AppGlobalize>
            </AppConnectServers>
          )}
        />
      </SnackbarProvider>
    </ThemeProvider>
  );
};
