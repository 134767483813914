const messages = {
  el: {
    app: {
      about: 'Σχετικά με την εφαρμογή',
      actions: {
        add: 'Προσθήκη',
        addNamed: 'Προσθήκη {name}',
        remove: 'Κατάργηση',
        removeNamed: 'Κατάργηση {name}',
        removeConfirmation: 'Είστε βέβαιοι ότι θέλετε να καταργήσετε?',
        more: 'Περισσότερα',
        less: 'Λιγότερα',
        download: 'Λήψη',
        save: 'Αποθήκευση',
        cancel: 'Ακύρωση',
        close: 'Κλείσιμο',
        moreFormFields: 'Περισσότερα πεδία',
        export: 'Εξαγωγή',
        edit: 'Επεξεργασία',
        createdSuccessMessage: 'Δημιουργήθηκε με επιτυχία.',
        savedSuccessMessage: 'Αποθηκεύτηκε με επιτυχία.',
        deletedSuccessMessage: 'Διαγράφηκε με επιτυχία',
        detail: 'Λεπτομέρειες',
        next: 'Επόμενο',
        back: 'Πίσω',
        start: 'Έναρξη',
        whatNext: 'Μετά τι ...',
        confirm: 'Επιβεβαίωση',
        confirmRemove: 'Είστε βέβαιοι ότι θέλετε να καταργήσετε?',
        search: 'Αναζήτηση',
        userProfile: 'Προφίλ Χρήστη',
        tenantProfile: '',
        imports: 'Εισαγωγές',
        fileImports: 'Εισαγωγή αρχείων',
        settings: 'Ρυθμίσεις',
        logout: 'Αποσύνδεση',
        login: 'Σύνδεση',
        filterList: 'Λίστα Φίλτρων',
        excel: 'Excel',
        refresh: 'Ανανέωση',
        clear: 'Εκκαθάριση',
        agree: 'Συμφωνώ',
        disagree: 'Διαφωνώ',
        makeCopy: 'Δημιουργία αντιγράφου',
        fullscreen: 'Πλήρης οθόνη',
        fullscreenClose: 'Κλείσιμο λειτουργίας πλήρους οθόνης',
        copy: 'Αντιγραφή',
      },
      messages: {
        confirmRemove: 'Είστε βέβαιοι ότι θέλετε να καταργήσετε αυτό το στοιχείο?',
        errorOccurred: 'Παρουσιάστηκε σφάλμα',
        createdSuccess: 'Δημιουργήθηκε με επιτυχία.',
        savedSuccess: 'Αποθηκεύτηκε με επιτυχία.',
        deletedSuccess: 'Η διαγραφή ήταν επιτυχής.',
        uploadSuccess: 'Το αρχείο μεταφορτώθηκε με επιτυχία.',
        uploadError: 'Σφάλμα κατά τη μεταφόρτωση αρχείου.',
        uploadInProgress: 'Μεταφόρτωση ...',
        uploadedFiles:
          '{number, plural, one {1 αρχείο μεταφορτώθηκε} few {{number} αρχεία μεταφορτώθηκαν} other {{number} αρχεία μεταφορτώθηκαν}}',
      },
      error: 'Σφάλμα!',
      validations: {
        isRequired: 'Το πεδίο {field} απαιτείται.',
        minLengthIsRequired:
          'Απαιτούνται τουλάχιστον {number, plural, one {1 αντικείμενο} few {{number} αντικείμενα} other {{number} αντικείμενα}}.',
        minValue: 'Η μέγιστη επιτρεπόμενη τιμή είναι {value}.',
        maxValue: 'Η μέγιστη επιτρεπόμενη τιμή είναι {value}.',
        passwordMatchError: 'Οι κωδικοί πρόσβασης δεν ταιριάζουν',
        invalidEmail: 'Μη έγκυρο email.',
        invalidPhoneNumber:
          'Μη έγκυρος αριθμός τηλεφώνου. Εισαγάγετε έναν αριθμό τηλεφώνου στη διεθνή μορφή " + X XXX XXX XXX " .',
        notValidNumber: 'Μη έγκυρος αριθμός',
        invalidUrl: 'Μη έγκυρο URL.',
        positive: 'Ο αριθμός πρέπει να είναι θετικός.',
        negative: 'Ο αριθμός πρέπει να είναι αρνητικός.',
      },
      login: {
        title: '',
        username: 'Όνομα χρήστη',
        password: 'Κωδικός πρόσβασης',
        loginButton: 'Σύνδεση',
        error: 'Η σύνδεση απέτυχε. Το όνομα χρήστη ή ο κωδικός πρόσβασης δεν βρέθηκαν',
        pleaseRelogin: 'Δεν φαίνεται να είστε συνδεδεμένοι. Συνδεθείτε ξανά.',
      },
      days: '{count, plural, one {1 ημέρα} few {{formattedCount} ημέρες} other {{formattedCount} ημέρες}}',
      overdue:
        'καθυστέρηση {count, plural, one {1 ημέρα} few {{formattedCount} ημέρες} other {{formattedCount} ημέρες}}',
      newItem: 'Νέο',
      anonymizing: {
        confirm: {
          title: '',
          content: '',
        },
        button: '',
      },
      appMenu: {
        discover: '',
      },
      missingType: '',
      missingTypeForEntityType: '',
      emptyScoringBlog: '',
    },
    components: {
      buttons: {
        showOnMap: '',
      },
      filters: {
        filterButtonSubmit: 'Αναζήτηση',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Προσθήκη συνθήκης',
        resetFilter: 'Επαναφορά φίλτρου',
        operators: {
          contains: {
            0: 'περιέχει',
            _: 'περιέχει',
          },
          containsexact: {
            0: 'περιέχει ακριβώς',
            _: 'περιέχει ακριβώς',
          },
          notcontains: {
            0: 'δεν περιέχει',
            _: 'δεν περιέχει',
          },
          betweennumber: {
            0: 'από',
            1: 'έως',
            _: 'μεταξύ',
          },
          betweendate: {
            0: 'από',
            1: 'έως',
            _: 'μεταξύ',
          },
          equal: {
            0: 'είναι ίσο',
            _: 'είναι ίσο',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Σύνολο αντικειμένων: {number}',
        nextPage: 'Φόρτωση {number} αντικειμένων',
        emptyMessage: 'Δεν βρέθηκαν αντικείμενα.',
      },
      selects: {
        emptyOption: 'όχι',
        emptyMessage: 'Δεν βρέθηκαν αντικείμενα.',
      },
      tables: {
        labelRowsPerPage: 'Γραμμές ανά σελίδα',
        sortBy: 'Ταξινόμηση κατά {field}',
        recordsChanged: 'Ορισμένες εγγραφές έχουν προστεθεί ή αλλάξει.',
      },
      textField: {
        errorMsgWrapper: 'Σφάλμα: {msg}',
      },
      dateField: {
        invalidDateFormat: 'Μη έγκυρη μορφή ημερομηνίας.',
        invalidDate: 'Άγνωστο',
        maxDateMessage: 'Η ημερομηνία δεν μπορεί να είναι μεγαλύτερη από τη μέγιστη ημερομηνία',
        minDateMessage: 'Η ημερομηνία δεν μπορεί να είναι μικρότερη από την ελάχιστη ημερομηνία',
        today: 'Σήμερα',
      },
      detailNavigation: {
        scoringblog: 'Ιστολόγιο Βαθμολογίας',
        card: 'Card',
        relations: 'Σχέσεις',
      },
      manipulationInfo: {
        created: 'Δημιουργήθηκε',
        updated: 'Ενημερώθηκε',
        removed: 'Καταργήθηκε',
      },
    },
  },
};

export default messages;
