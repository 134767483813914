import PropTypes from 'prop-types';
import compMessages from './react-mui-components/el';
import tablesMessages from './react-mui-tables/el';
import formsMessages from './react-forms/el';
import comMessages from './react-com/el';
import shoppingCartMessages from './react-shopping-cart/el';
import comOrdersMessages from './react-com-orders/el';
import messages from './el';

function MessagesEl({ children }) {
  return children([
    compMessages,
    tablesMessages,
    formsMessages,
    comMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

MessagesEl.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesEl;
