const messages = {
  el: {
    shoppingCart: {
      actions: {
        addToCart: 'Προσθήκη στο καλάθι',
        buy: 'Αγορά',
        order: 'Παραγγελία',
        remove: 'Αφαίρεση',
      },
      cart: {
        total: {
          label: 'Σύνολο',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Σύνολο ανά γραμμή',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Σύνολο για αποστολή',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Συνολική πληρωμή',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Συνολικοί φόροι',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Επαναφορά καλαθιού',
      },
      messages: {
        confirmResetCart: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τα περιεχόμενα του καλαθιού?',
        giftClaim: 'Μπορείτε να λάβετε {quantity} κομμάτια του {productName} ως δώρο.',
        cartManipulations: {
          allRemoved: 'Όλα τα αντικείμενα του καλαθιού έχουν αφαιρεθεί.',
          itemAdded: 'Στο καλάθι {number, plural, one {προστέθηκε αντικείμενο} other {προστέθηκαν αντικείμενα}}.',
          itemRemoved: 'Από το καλάθι {number, plural, one {ένα αντικείμενο αφαιρέθηκε} other {αφαιρέθηκαν}}.',
          quantityChaged: 'Η ποσότητα αγοράς έχει αλλάξει.',
        },
      },
      components: {
        appBartActionCart: 'Καλάθι',
        quantityField: {
          states: {
            quantityRounded: 'Η ποσότητα στρογγυλοποιήθηκε στο πλησιέστερο μέγεθος πακέτου.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Το καλάθι σας είναι άδειο',
        },
        cartAfterAddModal: {
          continueShopping: 'Συνέχεια αγορών',
          goToCart: 'Μετάβαση στο καλάθι',
          title: 'Προϊόντα που προστέθηκαν στο καλάθι',
          pcs: 'τμχ',
        },
      },
    },
  },
};

export default messages;
