const messages = {
  el: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Καλάθι',
        },
      },
      productDetail: {
        parameters: {
          title: 'Παράμετροι',
        },
      },
      productAvailability: {
        format: {
          available: 'Σε απόθεμα',
          moreThanX: 'Περισσότερα από {min} τεμάχια σε απόθεμα',
          exactCount: '{value} τεμ σε απόθεμα',
          notAvailable: 'Μη διαθέσιμο',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} περισσότερα',
      },
      relatedProducts: {
        title: 'Σχετικά προϊόντα',
      },
      productAccessories: {
        title: 'Αξεσουάρ',
      },
      productDetailImageGallery: {
        noImage: 'Χωρίς εικόνα',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Πότε θα πάρω τα προϊόντα;',
      },
    },
  },
};

export default messages;
