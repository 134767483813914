const messages = {
  el: {
    reactMuiTables: {
      selected: 'επιλεγμένο',
      filterhide: 'Απόκρυψη φίλτρου',
      filtershow: 'Εμφάνιση φίλτρου',
      refresh: 'Ανανέωση',
      filter: {
        actions: {
          search: 'Αναζήτηση',
          resetFilter: 'Επαναφορά Φίλτρου',
          addCondition: 'Προσθήκη Συνθήκης',
        },
      },
      standardOperators: {
        equals: 'ισούται με',
        contains: 'περιέχει',
        between: {
          label: 'μεταξύ',
          from: 'από',
          to: 'έως',
        },
        oneOf: 'ένα από',
      },
      labelRowsPerPage: 'Γραμμές ανά σελίδα:',
    },
  },
};

export default messages;
