const messages = {
  el: {
    orders: {
      actions: {
        addNewOrder: 'Προσθήκη παραγγελίας',
        all: 'Όλες οι παραγγελίες',
      },
      listPage: {
        title: 'Παραγγελίες',
        table: {
          filter: {
            number: 'Αριθμός',
          },
          columns: {
            contact: 'Επικοινωνία',
            dateOfOrder: 'Ημερομηνία Παραγγελίας',
            number: 'Αριθμός',
            offer: 'Προσφορά',
            opportunity: 'Ευκαιρία',
            owner: 'Ιδιοκτήτης',
            state: 'Κατάσταση',
            total: 'Σύνολος',
            totalUserCurrency: 'Σύνολο σε {currency}',
          },
        },
      },
      order: {
        newEntity: 'Νέα παραγγελία',
        contact: {
          label: 'Επικοινωνία',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Συνολική προμήθεια',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Νόμισμα',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Ημερομηνία παραγγελίας',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Αριθμός',
          helperText: 'Αφήστε κενό για αυτόματη δημιουργία αριθμών.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Προσφορά',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Ευκαιρία',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Ιδιοκτήτης',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Περιθώριο πωλήσεων',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Θέμα',
          discount: {
            label: 'Έκπτωση',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Προμήθεια',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Μέγεθος πακέτου',
          },
          listPrice: {
            label: 'Τιμή καταλόγου',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Τιμή πώλησης',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Προϊόν',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Ποσότητα',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Περιθώριο πωλήσεων',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Σύνολο για αντικείμενο',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Μονάδα',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Σύνολο',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {κανένα αντικείμενο} one {1 αντικείμενο} few {{number} αντικείμενα} other {{number} αντικείμενα}}',
      },
      myOrdersScoringBlog: {
        title: 'Οι παραγγελίες μου',
      },
      ordersList: 'Παραγγελίες',
    },
  },
};

export default messages;
