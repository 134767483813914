// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleEl from '@sprinx/react-globalize/CldrLocaleEl';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
import React from 'react';
import MessagesEl from './i18n/MessagesEl';

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}
const defaultLocale = 'el';
const supportedLocales = ['el'];

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  return (
    <GlobalizeProvider
      cldrs={{ el: CldrLocaleEl }}
      messages={{ el: MessagesEl }}
      defaultLocale={defaultLocale}
      supportedLocales={supportedLocales}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
