const messages = {
  el: {
    sprinxFormsValidation: {
      required: 'Το πεδίο απαιτείται!',
      string: 'Πρέπει να είναι συμβολοσειρά κειμένου!',
      stringEmpty: 'Δεν πρέπει να είναι κενό!',
      stringMin: 'Το μήκος πρέπει να είναι μεγαλύτερο ή ίσο με τους {expected} χαρακτήρες!',
      stringMax: 'Το μήκος πρέπει να είναι μικρότερο ή ίσο με τους {expected} χαρακτήρες!',
      stringLength: 'Το μήκος πρέπει να είναι {expected} χαρακτήρες!',
      stringPattern: 'Δεν πληροί το απαιτούμενο μοτίβο!',
      stringContains: "Πρέπει να περιέχει το κείμενο '{expected}'!",
      stringEnum: 'Δεν ταιριάζει με καμία από τις επιτρεπόμενες τιμές!',
      stringNumeric: 'Πρέπει να είναι αριθμός!',
      number: 'Πρέπει να είναι αριθμός!',
      numberMin: 'Πρέπει να είναι μεγαλύτερο ή ίσο με το {expected}!',
      numberMax: 'Πρέπει να είναι μικρότερο ή ίσο με το {expected}!',
      numberEqual: 'Πρέπει να είναι ίσο με το {expected}!',
      numberNotEqual: 'Δεν μπορεί να είναι ίσο με το {expected}!',
      numberInteger: 'Πρέπει να είναι ακέραιος!',
      numberPositive: 'Πρέπει να είναι θετικός αριθμός!',
      numberNegative: 'Πρέπει να είναι αρνητικός αριθμός!',
      array: 'Πρέπει να είναι μια λίστα!',
      arrayEmpty: 'Δεν πρέπει να είναι κενή λίστα!',
      arrayMin:
        'Απαιτούνται τουλάχιστον {number, plural, one {1 αντικείμενο} few {{number} αντικείμενα} other {{number} αντικείμενα}}!',
      arrayMax:
        'Δεν μπορεί να περιέχει περισσότερα από {number, plural, one {1 αντικείμενο} few {{number} αντικείμενα} other {{number} αντικείμενα}}!',
      arrayLength:
        'Πρέπει να περιέχει ακριβώς {expected, plural, one {1 αντικείμενο} few {{number} αντικείμενα} other {{number} αντικείμενα}}!',
      arrayContains: "Πρέπει να περιέχει '{expected}'!",
      arrayEnum: "Η τιμή '{expected}' δεν ταιριάζει με καμία από τις επιτρεπόμενες τιμές!",
      boolean: "Πρέπει να είναι 'ναι' ή 'όχι'!",
      function: 'Πρέπει να είναι μια συνάρτηση!',
      date: 'Πρέπει να είναι ημερομηνία!',
      dateMin: 'Πρέπει να είναι μεγαλύτερο ή ίσο με το {expected}!',
      dateMax: 'Πρέπει να είναι μικρότερο ή ίσο με το {expected}!',
      forbidden: 'Το πεδίο απαγορεύεται!',
      email: 'Μη έγκυρο email!',
      url: 'Μη έγκυρη διεύθυνση URL!',
      object: 'Πρέπει να είναι τύπου Object!',
      arrayReducedEquals: 'Η συνοπτική τιμή δεν ταιριάζει με το {expected}!',
      phoneNumber: "Πρέπει να είστε έγκυρος αριθμός τηλεφώνου στη διεθνή μορφή '+ XXX XXX XXX XXX'!",
      dateGt: 'Η ημερομηνία πρέπει να είναι μεγαλύτερη από {expected}!',
      dateGte: 'Η ημερομηνία πρέπει να είναι μεγαλύτερη ή ίση με {expected}!',
      dateLt: 'Η ημερομηνία πρέπει να είναι μικρότερη από {expected}!',
      dateLte: 'Η ημερομηνία πρέπει να είναι μικρότερη ή ίση με {expected}!',
    },
  },
};

export default messages;
